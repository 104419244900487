import Apiservice from '../api.service';
import { API_URL } from '../../../common/config.js'
const apiService = Apiservice;


export default class SupportService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/student/support-request`;

      }

      post(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }


}
